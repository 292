<template>
  <v-app>
    <navigation-drawer />
    <v-app-bar app color="primary" dark> Wepala </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import "weos-ui/dist/weos-ui.css";
import NavigationDrawer from "./components/NavigationDrawer.vue";

export default {
  name: "App",

  components: {
    NavigationDrawer,
  },

  data: () => ({
    sidebarMenu: true,
    toggleMini: false,
    items: [
      {
        title: "Home",
        href: "/",
        icon: "mdi-account-box-multiple-outline",
      },
      {
        title: "About",
        href: "/about",
        icon: "mdi-account-outline",
      },
      {
        title: "Settings",
        href: "/{{.AccountName}}/settings",
        icon: "mdi-settings-outline",
      },
    ],
  }),
};
</script>

