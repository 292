<template>
  <v-navigation-drawer
    fixed
    app
    color="#fafafa"
    floating
    :permanent="true"
    :mini-variant.sync="mini"
  >
    <v-list dense color="primary" dark>
      <v-list-item>
        <v-list-item-action>
          <v-icon @click.stop="toggleMini = !toggleMini">{{
            toggleMini ? "mdi-menu" : "mdi-chevron-left"
          }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <h3 class="font-weight-thin">ShopWithWe Reseller</h3>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-list-item-avatar>
      <v-list-item-content class="text-truncate">
        Jane Doeski
      </v-list-item-content>
    </v-list-item>
    <v-list-item to="/">
      <v-list-item-icon>
        <v-icon color="primary">mdi-home-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="primary--text">Dashboard</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-for="item in items" :key="item.title" :to="item.href">
        <v-list-item-icon>
          <v-icon color="primary">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="primary--text">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",

  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  data: () => ({
    toggleMini: false,
    items: [
      {
        title: "About",
        href: "/about",
        icon: "mdi-account-outline",
      },
      {
        title: "Settings",
        href: "/settings",
        icon: "mdi-cog-outline",
      },
    ],
  }),
};
</script>
